import { useAuth } from 'contexts'
import { useUserProfile } from 'hooks'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useEffect, useState } from 'react'

/**
 * Scripts should be put at the end of the main content so the browser will parse the document first.
 */
export const ThirdPartyScriptsBody = () => {
  const { isLoading } = useAuth()

  const [shouldTrack, setShouldTrack] = useState(false)

  // Disable Third Party Scripts if user has opted out
  useUserProfile({
    options: {
      enabled: !isLoading,
      onSuccess(userProfile) {
        if (!userProfile) {
          setShouldTrack(true)
          return
        }

        setShouldTrack(userProfile.disableNonEssentialCookies !== true)
      },
      onError() {
        setShouldTrack(true)
      },
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  })

  // MS Clarity Identify API Integration
  // useEffect(() => {
  //   if (typeof window === 'undefined') return
  //   if (!window.clarity) return
  //   if (!router.asPath) return
  //   if (!shouldTrack) return
  //   if (!user) return

  //   window.clarity(
  //     'identify',
  //     user?.email,
  //     `session-${user?.id}-${Date.now()}`,
  //     router.asPath
  //   )
  // }, [router.asPath, shouldTrack, user])

  if (!shouldTrack) {
    return null
  }
  const env = process.env.NEXT_PUBLIC_STAGE

  return (
    <>
      {env === 'production' && (
        <>
          {/* {
            <Script id='ms-clarity' strategy='afterInteractive'>
              {`(function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "k469m2lf2c");`}
            </Script>
          } */}

          {/* Google Tag Manager Body */}
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<iframe src="https://fairart.com/metrics/ns.html?id=GTM-M94WMRKG" height="0" width="0" style="display:none;visibility:hidden"></iframe>',
            }}
          ></noscript>
        </>
      )}
    </>
  )
}
