// import Script from 'next/script'

export const ThirdPartyScriptsHead = () => {
  const env = process.env.NEXT_PUBLIC_STAGE
  return (
    <>
      {env === 'production' && (
        <>
          <script defer>
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://fairart.com/metrics/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-M94WMRKG');`}
          </script>
        </>
      )}
    </>
  )
}
